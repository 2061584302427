/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
	color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
	color: #333;
	text-shadow: none; /* Prevent inheritance from `body` */
	background-color: #fff;
	border: 0.05rem solid #fff;
}

/*
 * Base structure
 */

html,
body,
#root,
#app {
	min-height: 100vh;
	background-color: #333;
}

#app {
	display: -ms-flexbox;
	display: flex;
	color: #fff;
	text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

.cover-container {
	max-width: 42em;
}

/*
 * Header
 */
.masthead {
	margin-bottom: 2rem;
}

.masthead-brand {
	margin-bottom: 0;
}

.nav-masthead .nav-link {
	padding: 0.25rem 0;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.5);
	background-color: transparent;
	border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: #fff;
	border-bottom-color: #fff;
}

@media (min-width: 48em) {
	.masthead-brand {
		float: left;
	}
	.nav-masthead {
		float: right;
	}
}

/*
 * Cover
 */
.cover {
	padding: 0 1.5rem;
}
.cover .btn-lg {
	padding: 0.75rem 1.25rem;
	font-weight: 700;
}

/*
 * Footer
 */
.mastfoot {
	color: rgba(255, 255, 255, 0.5);
}
